<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      v-model="updateData"
      :emitInput="true"
    >
    </WsCreate>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/news";
export default {
  metaInfo() {
    return {
      title: `新增${this.label}`,
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["content"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: [
                "title",
                "description",
                "publish_at",
                "cover_image",
                "tags",
              ],
            },
          ],
        },
      ],
    },
  }),
};
</script>

<style>
</style>